<template>
  <div class="chosen-brand">
    <div class="chosen-brand__item">
      <div class="chosen-brand__item-inner">
        <h3>{{ brand.brands }}</h3>
      </div>
    </div>

    <div
      class="chosen-brand__item-upload"
      :class="{ 'has-image': brand.logoURL || loading }"
    >
      <a-upload-dragger
        name="file"
        accept="image/jpeg, image/png, image/jpg"
        :multiple="false"
        :showUploadList="false"
        :customRequest="uploadBrandImage"
        ref="upload"
      >
        <Loader class="space-0" text="" v-if="loading"></Loader>
        <div class="chosen-brand__item-upload-info" v-else-if="!brand.logoURL">
          <span
            >Drag and Drop image here <br />
            Or</span
          >
          <div>Browse Files</div>
        </div>

        <img v-else :src="brand.logoURL" alt="Logo" />
      </a-upload-dragger>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
export default {
  name: "ChosenBrandItem",
  components: { Loader },
  props: {
    brand: {
      type: Object,
    },
    surveyID: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    uploadBrandImage(logo) {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", logo.file);
      formData.append("surveyId", this.surveyID);

      const data = {
        wsName: "UploadBrandImage",
        data: {
          form: formData,
        },
      };

      const saveBrandImage = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      saveBrandImage.then((data) => {
        if (data.success) {
          this.$notification["success"]({
            message: "Well done!",
            description: "Image successfully uploaded.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.$emit("set-brand-image", data.imageURL);
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Image uploading failed. Please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
        this.loading = false;
      });
    },
  },
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.chosen-brand {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:before {
    left: 40px;
    right: 40px;
    height: 2px;
    position: absolute;
    top: 50%;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='11, 28' stroke-linecap='square'/%3E%3C/svg%3E");
  }
  &__item {
    width: 300px;
    padding: 20px;
    border-radius: 20px;
    overflow: hidden;
    flex-direction: column;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='16, 28' stroke-linecap='square'/%3E%3C/svg%3E")
      #fff;
    position: relative;
  }
  &__item-inner {
    padding: 20px 12px;
    border-radius: 10px;
    border: 1px solid var(--red);
    box-shadow: 0 0 0 3px var(--red);
    background: #fff;
    &:hover {
      border-color: var(--red);
    }
    h3 {
      color: var(--dark);
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.32px;
      flex: 1;
      margin-bottom: 0;
    }
  }
  &__item-upload {
    width: 300px;
    height: 300px;
    padding: 20px;
    border-radius: 20px;
    overflow: hidden;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23fab3c7' stroke-width='4' stroke-dasharray='16, 28' stroke-linecap='square'/%3E%3C/svg%3E")
      #fff;
    position: relative;

    &.has-image {
      &:after {
        display: none;
      }
    }

    img {
      max-width: 100%;
    }

    &:after {
      position: absolute;
      left: 50%;
      top: 65px;
      transform: translateX(-50%);
      content: "";
      width: 41px;
      height: 41px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 312.602 312.602' style='enable-background:new 0 0 312.602 312.602' xml:space='preserve'%3E%3Cpath style='fill:%23000002' d='M251.52 137.244c-3.966 0-7.889.38-11.738 1.134-1.756-47.268-40.758-85.181-88.448-85.181-43.856 0-80.964 32.449-87.474 75.106C28.501 129.167 0 158.201 0 193.764c0 36.106 29.374 65.48 65.48 65.48h54.782c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5H65.48c-27.835 0-50.48-22.645-50.48-50.48 0-27.835 22.646-50.48 50.48-50.48 1.367 0 2.813.067 4.419.206l7.6.658.529-7.61c2.661-38.322 34.861-68.341 73.306-68.341 40.533 0 73.51 32.977 73.51 73.51 0 1.863-.089 3.855-.272 6.088l-.983 11.968 11.186-4.367a45.863 45.863 0 0 1 16.747-3.151c25.409 0 46.081 20.672 46.081 46.081 0 25.408-20.672 46.08-46.081 46.08-.668 0-20.608-.04-40.467-.08-19.714-.04-39.347-.08-39.999-.08-4.668 0-7.108-2.248-7.254-6.681v-80.959l8.139 9.667a7.497 7.497 0 0 0 10.567.907 7.498 7.498 0 0 0 .907-10.567l-18.037-21.427c-2.272-2.699-5.537-4.247-8.958-4.247-3.421 0-6.686 1.548-8.957 4.247l-18.037 21.427a7.498 7.498 0 0 0 .907 10.567 7.466 7.466 0 0 0 4.826 1.763 7.482 7.482 0 0 0 5.741-2.67l7.901-9.386v80.751c0 8.686 5.927 21.607 22.254 21.607.652 0 20.27.04 39.968.079 19.874.041 39.829.081 40.498.081 33.681 0 61.081-27.4 61.081-61.08 0-33.681-27.401-61.081-61.082-61.081z'/%3E%3C/svg%3E")
        center center no-repeat;
      z-index: 1;
      pointer-events: none;
    }

    ::v-deep .ant-upload {
      background: #f4f4f4;
      padding: 0 20px;
      border: none;
      border-radius: 12px;
      margin-bottom: 20px;
      .ant-upload-btn {
        border: 0;
      }
    }
    ::v-deep .ant-upload-drag-container {
      svg {
        font-size: 34px;
        color: #7e7e7e;
        margin-bottom: 12px;
        &.fa-check-circle {
          color: #1990ff;
        }
      }
      p {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  &__item-upload-info {
    color: var(--darkText);
    margin-top: 40px;
    span {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.28px;
      display: block;
      text-align: center;
      margin-bottom: 5px;
    }
    div {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.28px;
      border-radius: 10px;
      border: 2px solid rgba(28, 28, 28, 0.3);
      padding: 8px;
      width: 110px;
      text-align: center;
      margin: 0 auto;
    }
  }
}
</style>
